import { DOMAIN, WP_URL } from "@/plugins/consts";

export default {
    token: 'mover_app_token',

    // for wpengine
    wp_dev_url: 'https://tophelpersdev.wpenginepowered.com',
    wp_dev_domain: '.wpenginepowered.com',

    get () {
        return $cookies.get(this.token);
    },

    save(token) {
        this.setCookie(token);
        localStorage.setItem(this.token, token);
    },

    delete() {
        this.removeCookie();
        localStorage.removeItem(this.token);
    },

    setCookie(token) {
        const options = { expires: 60 * 60 * 24 * 7, path: '/' };

        $cookies.set(this.token, token, { ...options, domain: DOMAIN });

        if (WP_URL === this.wp_dev_url) {
            $cookies.set(this.token, token, { ...options, domain: this.wp_dev_domain });
        }
    },

    removeCookie() {
        $cookies.remove(this.token, '/', DOMAIN);

        if (WP_URL === this.wp_dev_url) {
            $cookies.remove(this.token, '/', this.wp_dev_domain);
        }
    }
}
